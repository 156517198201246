import "@inovua/reactdatagrid-enterprise/index.css";
import {
  Button,
  FormLabel,
  Grid,
  Link,
  OutlinedInput,
  Stack
} from "@mui/material";
import { withStyles } from "@mui/styles";
import React from "react";
import Dropzone from "react-dropzone";
import { toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import uuid from "react-uuid";
/*----------------------Cac control trong project su dung den trong form----------------*/
import SimpleDialog from "../../components/SimpleDialog";
import { formStyles, handleServerError, showError } from "../../components/common";

/*-----------------------Cac thu vien dung chung -------------------------*/
const myLib = require("../../lib/MyLib");
const server = require("../../lib/server");
/*-----------------------Cac dinh nghia ----------------------------------*/
const englishUI = true;
const buttonLabels = englishUI
  ? {
    btnSave: "Save",
    btnDelete: "Delete",
    btnDownload: "Download",
    btnTemplate: "Download template"
  }
  : {
    btnSave: "Lưu",
    btnDelete: "Xóa",
    btnDownload: "Tải về",
    btnTemplate: "Tải file mẫu"
  };

const fieldLabels_EN = {
  SoSanhChaoGia: "So sánh chào giá:",
  BienBanLuaChonNhaThau: "Biên bản lựa chọn nhà thầu:"
};
const fieldLabels_VN = {
  SoSanhChaoGia: "So sánh chào giá:",
  BienBanLuaChonNhaThau: "Biên bản lựa chọn nhà thầu:"
};
const fieldLabels = englishUI ? fieldLabels_EN : fieldLabels_VN;

class AttachmentDocumentsTab extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      dataSaved: false, //du lieu da tung duoc save hay chua
      after: {
        FileSoSanh: null,
        FileBienBan: null
      },
      Attachs: []
    };
  }

  componentDidMount() {
    this.loadData();
  }

  loadData() {
    server
      .post("ChiPhi/DoRequest", {
        Function: "Proc_CP_HangMucSC_Get",
        ThamSo: {
          HangMucID: this.props.HangMucID,
        },
      }).then((response) => {
        if (Array.isArray(response.CP_HangMucSC_Att)) {
          let s = this.state;
          s.after = {
            ...s.after,
            FileSoSanh: response.CP_HangMucSC_Att.find(att => att.PhanLoai == 1),
            FileBienBan: response.CP_HangMucSC_Att.find(att => att.PhanLoai == 2),
          };

          this.setState(s);
        }
      }).catch((error) => {
        showError(error);
      });
  }

  readOneFile(fileData) {
    //tao promies de doc 1 file
    return new Promise((resolve, reject) => {
      if (fileData.File) {
        var fr = new FileReader();
        fr.onload = async (e) => {
          const text = e.target.result;
          fileData.Content = Buffer.from(text).toString("base64");
          fileData.File = undefined; //xoa file di
          resolve(fileData);
        };
        fr.onerror = reject;
        fr.readAsArrayBuffer(fileData.File);
      } else {
        resolve(fileData);
      }
    });
  }

  removeExistFile(phanLoai) {
    if (phanLoai == 1 && this.state.after.FileSoSanh?.FileID) {
      return server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_HangMucSC_Att_Update",
          ThamSo: {
            FileID: this.state.after.FileSoSanh.FileID,
          },
        });
    } else if (phanLoai == 2 && this.state.after.FileBienBan?.FileID) {
      return server
        .post("ChiPhi/DoRequest", {
          Function: "Proc_CP_HangMucSC_Att_Update",
          ThamSo: {
            FileID: this.state.after.FileBienBan.FileID,
          },
        })
    }

    return Promise.resolve();
  }

  doSave = (file, phanLoai) => {
    try {
      this.setState({ saving: true, errorLog: "" });
      const fileData = {
        File: file,
        FileID: uuid(),
        FileName: file.name,
        Content: null,
      };

      this.readOneFile(fileData)
        .then(att => {
          this.removeExistFile(phanLoai).then(_ => {
            server
              .post("ChiPhi/DoRequest", {
                Function: "Proc_CP_HangMucSC_Att_Update",
                ThamSo: {
                  HangMucID: this.props.HangMucID,
                  LoaiFile: phanLoai == 1 ? "So sánh chào giá" : phanLoai == 2 ? "Biên bản chọn nhà thầu" : "Khác",
                  PhanLoai: phanLoai,
                  FileName: att.FileName,
                  Content: att.Content,
                },
              })
              .then(() => {
                this.loadData();
                toast.success("Saved data successfully.")
                this.setState({ saving: false });
              })
              .catch((error) => {
                handleServerError(error);
                this.setState({ saving: false });
              });
          });
        });
    } catch (error) {
      this.setState({ saving: false });
      handleServerError(error);
    }
  };

  render() {
    return <>
      <>
        <Grid container columns={24} rowSpacing={2} columnSpacing={1}>
          <Grid item xs={4}>
            <FormLabel>{fieldLabels.SoSanhChaoGia}</FormLabel>
          </Grid>
          <Grid item xs={10}>
            <OutlinedInput
              fullWidth
              value={this.state.after.FileSoSanh?.FileName ?? ""}
              readOnly
              placeholder="So sánh chào giá"
            />
          </Grid>
          <Grid item xs={2}>
            <Dropzone
              maxSize={2 * 1024 * 1024}
              multiple={false}
              accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [] }}
              validator={(file) => {
                if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                  return null;
                }
                alert("File too large");
                return true;
              }}
              onDrop={(acceptedFiles) => {
                if (acceptedFiles.length > 0) {
                  this.doSave(acceptedFiles[0], 1);
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button fullWidth variant="contained">
                      Upload
                    </Button>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={8}>
            {this.state.after.FileSoSanh && <Stack alignItems="center" height="100%" direction="row" spacing={2}>
              <Link href={server.getRequest(`ChiPhi/GetFile?fileID=${this.state.after.FileSoSanh.FileID}`)}>
                {buttonLabels.btnDownload}
              </Link>
              <Link
                href="#"
                sx={{ color: "red", zIndex: 0 }}
                onClick={() => {
                  this.setState({ showConfirmXoa: true, fileToDelete: this.state.after.FileSoSanh });
                }}
              >
                {buttonLabels.btnDelete}
              </Link>
            </Stack>}
          </Grid>

          <Grid item xs={4}>
            <FormLabel>{fieldLabels.BienBanLuaChonNhaThau}</FormLabel>
          </Grid>
          <Grid item xs={10}>
            <OutlinedInput
              fullWidth
              value={this.state.after.FileBienBan?.FileName ?? ""}
              readOnly
              placeholder="Biên bản lựa chọn nhà thầu"
            />
          </Grid>
          <Grid item xs={2}>
            <Dropzone
              maxSize={2 * 1024 * 1024}
              multiple={false}
              accept={{ "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet": [] }}
              validator={(file) => {
                if (file.size > 0 && file.size < 2 * 1024 * 1024) {
                  return null;
                }
                alert("File too large");
                return true;
              }}
              onDrop={(acceptedFiles) => {
                if (acceptedFiles.length > 0) {
                  this.doSave(acceptedFiles[0], 2);
                }
              }}
            >
              {({ getRootProps, getInputProps }) => (
                <section>
                  <div {...getRootProps()}>
                    <input {...getInputProps()} />
                    <Button fullWidth variant="contained">
                      Upload
                    </Button>
                  </div>
                </section>
              )}
            </Dropzone>
          </Grid>
          <Grid item xs={8}>
            {this.state.after.FileBienBan && <Stack alignItems="center" height="100%" direction="row" spacing={2}>
              <Link href={server.getRequest(`ChiPhi/GetFile?fileID=${this.state.after.FileBienBan.FileID}`)}>
                {buttonLabels.btnDownload}
              </Link>
              <Link
                href="#"
                sx={{ color: "red", zIndex: 0 }}
                onClick={() => {
                  this.setState({ showConfirmXoa: true, fileToDelete: this.state.after.FileBienBan });
                }}
              >
                {buttonLabels.btnDelete}
              </Link>
            </Stack>}
          </Grid>
        </Grid>
      </>

      {this.state.showConfirmXoa ? (
        <SimpleDialog
          confirm
          message={
            "Bạn muốn xóa file: " + this.state.fileToDelete.FileName + " ?"
          }
          open={true}
          OKRunning={this.state.saving}
          close={(ok) => {
            if (ok) {
              //thuc hien xoa
              this.setState({ saving: true });
              server
                .post("ChiPhi/DoRequest", {
                  Function: "Proc_CP_HangMucSC_Att_Update",
                  ThamSo: {
                    FileID: this.state.fileToDelete.FileID,
                  },
                })
                .then(() => {
                  this.setState({ showConfirmXoa: false, saving: false });
                  //reload
                  this.loadData();
                })
                .catch((error) => {
                  this.setState({ saving: false });
                  handleServerError(error);
                });
            } else {
              this.setState({ showConfirmXoa: false });
            }
          }}
        />
      ) : null}
    </>
  }
}


export default withStyles(formStyles, { withTheme: true })(AttachmentDocumentsTab);